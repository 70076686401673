import React, { useEffect, useState } from "react";
import {
    handleFormErrors,
    handleFormSubmit,
    handleInputChange as handleChange,
    renderInputFields,
} from "../../services/formServices";
import modal from "../../services/modalServices";
import auth from "../../services/authServices";
import http from "../../services/httpServices";
import AdminForm from "../../components/ui/AdminForm";
import { toast } from "react-toastify";

const AddAdminToAdminTagsForm = ({
    user = { id: 0 },
    afterSuccess = () => null,
    onTable = false,
    parentAdminToAdminUserTags = [],
}) => {
    const [data, setData] = useState({
        tags: [],
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const [adminToAdminUserTags, setAdminToAdminUserTags] = useState([]);
    console.log("adminToAdminUserTags", adminToAdminUserTags);
    const getAdminToAdminUserTags = async () => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);

        const { data: response } = await http.get(`/api/admin_to_admin_user_tags/options`, config);
        setAdminToAdminUserTags(response);
    };

    useEffect(() => {
        if (!onTable) {
            getAdminToAdminUserTags();
        }
    }, []);

    useEffect(() => {
        if (parentAdminToAdminUserTags && parentAdminToAdminUserTags.length > 0) {
            setAdminToAdminUserTags(parentAdminToAdminUserTags);
        }
    }, [parentAdminToAdminUserTags]);

    const inputFields = [
        {
            id: "tags",
            type: "radio",
            options: adminToAdminUserTags,
            multiple: true,
            isIntValues: true,
            placeholder: "اختر",
        },
    ];
    const handleSubmit = (e) => {
        // return;
        setIsLoading(true);

        handleFormSubmit(e, setIsLoading, setErrors, () => {
            handleAdminToAdminNoteChange();
            // modal.message({
            //     title: "هل انت متأكد",
            //     text: ``,
            //     icon: "info",
            //     // button: "تأكيد",
            //     buttons: {
            //         confirm: "تأكيد",
            //         cancel: "إلغاء",
            //     },
            //     callback: (e) => {
            //         if (e && e !== "cancel") {
            //         } else {
            //             setIsLoading(false);
            //         }
            //     },
            // });
        });
    };
    const handleAdminToAdminNoteChange = async () => {
        const adminToken = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(adminToken);

        try {
            const { data: response } = await http.post(
                `/api/users/${user.id}/admin_to_admin_user_tag`,
                data,
                config
            );

            toast.success(`عملية ناجحة`, { theme: "colored" });

            setData({
                tags: [],
            });
            setIsLoading(false);
            afterSuccess(user, response.note);
        } catch ({ response }) {
            handleFormErrors(response, setIsLoading, setErrors, false);
        }
    };
    return (
        <>
            <AdminForm
                className={`${
                    onTable
                        ? "bg-third-container space-y-3 py-2 px-1 rounded-md clr-text-primary smooth border border-cyan-500 border-opacity-20"
                        : ""
                }`}
                onSubmit={handleSubmit}
                isLoading={isLoading}
                buttonText="اضافة تاجات للأدمن"
            >
                {inputFields.map((input, key) =>
                    renderInputFields(
                        key,
                        input.handleChange ? input.handleChange : handleChange,
                        data,
                        setData,
                        errors,
                        input
                    )
                )}
            </AdminForm>
        </>
    );
};

export default AddAdminToAdminTagsForm;
